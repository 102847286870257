<template>
  <div>
    <v-dialog v-model="viewBanner" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0"
          ><v-toolbar-title class="white--text"
            >Banner of {{ banneritems.banner_name }}</v-toolbar-title
          >
          <v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n2"
            >mdi-close</v-icon
          >
        </v-toolbar>

        <v-card-text>
          <div v-if="images != null">
            <v-card max-width="320" class="ml-2" flat>
              <v-carousel
                v-model="model"
                height="auto"
                hide-delimiters
                :show-arrows="false"
              >
                <v-carousel-item class="mt-5">
                  <v-img height="300" :src="images" contain></v-img>
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
export default {
  props: {
    viewBanner: Boolean,
    banneritems: Object,
  },
  components: {},
  data: () => ({
    worktype: "",
    model: 0,
    comment_work: "",
    formActionType: "previewproof",
    images: "",
  }),
  watch: {
    viewBanner: {
      handler() {
        if (this.viewBanner == true) {
          this.images = this.banneritems.banner_url;
        }
      },
      immediate: true,
    },
  },

  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
    },
  },
};
</script>
  
  <style>
</style>