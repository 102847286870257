<template>
  <div>
    <!-- eslint-disable -->
    <v-dialog v-model="bannerEdition" persistent max-width="500">
      <v-card>
        <v-toolbar dense class="elevation-0 gradient-bg white--text">
          <v-toolbar-title class="text--white ml-2"
            >Edit Banner</v-toolbar-title
          >
          <v-spacer />
          <v-icon @click="close_dialog()" class="white--text">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-row no-gutters class="mt-5">
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="banner_name"
                  label="Banner Name"
                  :rules="[(v) => !!v || 'File is required']"
                />
                <v-textarea
                  auto-grow
                  rows="0"
                  outlined
                  v-model="banner_description"
                  dense
                  label="Banner Description"
                  :rules="[(v) => !!v || 'File is required']"
                />
                <v-text-field
                  outlined
                  dense
                  label="Url"
                  :rules="rules"
                  v-model="banner_url"
                />

                <v-file-input
                  outlined
                  :disabled="urlupload != null"
                  dense
                  v-model="inputFiles"
                  :rules="fileRules"
                  label="Upload Banner"
                  accept="image/*"
                  @change="get_files(inputFiles)"
                ></v-file-input>
                <div class="mt-n3 ml-10">
                  <b>Banner size 600px X 340px</b>
                </div>
                <v-card flat class="image-container" v-show="urlupload != null">
                  <v-img max-height="200" contain :src="urlupload"> </v-img>
                  <v-btn
                    icon
                    @click="delete_item(urlupload)"
                    class="delete-btn"
                  >
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            dark
            @click="validate_data()"
            :loading="loading"
            class="gradient-bg white--text mr-2 mb-2"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    <script>
/* eslint-disable */
var AWS = require("aws-sdk");
import { Buffer } from "buffer";

import { API, graphqlOperation } from "aws-amplify";
import { edit_banner } from "@/graphql/mutations.js";
export default {
  props: {
    bannerEdition: Boolean,
    banneritems: Object,
  },
  data() {
    return {
      inputFiles: null,
      urlupload: null,

      banner_name: "",
      banner_description: "",
      banner_url: "",
      delLoading: false,
      loading: false,
      urlupload: null,
      selectedFile: {},
      imageDimensions: "",

      rules: [
        (v) => !!v || "URL is required",
        (v) => {
          const urlPattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
              "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|" + // domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // port and path
              "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
              "(\\#[-a-zA-Z\\d_]*)?$",
            "i"
          ); // fragment locator
          return urlPattern.test(v) || "Invalid URL";
        },
      ],
    };
  },
  watch: {
    bannerEdition: {
      handler() {
        {
          this.banner_name = this.banneritems.banner_name;
          this.banner_description = this.banneritems.banner_description;
          this.banner_url = this.banneritems.banner_routing_url;
          this.urlupload = this.banneritems.banner_url;
        }
      },
      immediate: true,
    },
  },
  computed: {
    fileRules() {
      return this.urlupload ? [] : [(v) => !!v || "File is required"];
    },
  },
  methods: {
    close_dialog() {
      this.inputFiles = null;
      this.$emit("clicked", 0);
      this.croppedImage = null;
      this.urlupload = null;
    },

    get_files(file) {
      this.selectedFile = file;
      if (file && file.type && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            this.imageDimensions = {
              width: img.width,
              height: img.height,
            };
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.imageDimensions = null;
      }
    },
    async delete_item(val) {
      var self = this;
      this.delLoading = true;
      var data = this.$store.getters.get_user_details[0].cabinetdetails;
      let uint8array = new Uint8Array(JSON.parse(data));
      // ENCODER

      var array = new TextDecoder("utf-8").decode(uint8array);
      this.asseceskey = JSON.parse(array);
      var s3Bucket = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: this.asseceskey.access_key,
        secretAccessKey: this.asseceskey.secret_key,
      });
      var bucketName = "przncebanners";

      var params = {
        Bucket: bucketName,
        Key: val,
      };

      await s3Bucket.deleteObject(params, function (err, data) {
        if (err) {
          console.log(err);
        } else if (data) {
          if (self.urlupload == val) {
            self.urlupload = null;
            self.inputFiles = null;
          }
        }
      });
    },
    async saveAction() {
      this.loading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(edit_banner, {
            input: {
              banner_name: this.banner_name,
              banner_id: this.banneritems.banner_id,
              banner_description: this.banner_description,
              banner_url: this.urlupload,
              banner_routing_url: this.banner_url,
              user_email_id: this.$store.getters.get_useremail,
            },
          })
        );
        var response = JSON.parse(result.data.edit_banner);
        this.loading = false;
        if (response.Status == "SUCCESS") {
          this.$emit("successMsg", response.Message);
          this.loading = false;
        } else {
          this.$emit("errorMsg", response.Message);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
        this.loading = false;
      }
    },
    validate_data() {
      if (this.$refs.form.validate()) {
        if (this.urlupload == null) {
          if (
            this.imageDimensions.height != 340 &&
            this.imageDimensions.width != 600
          ) {
            this.$emit("errorMsg", "The Banner size should be 600px by 340px");
          } else {
            this.upload_file();
          }
        } else {
          this.saveAction();
        }
      }
    },
    upload_file() {
      this.loading = true;
      var imageUrl = [];
      var newUrl = [];
      var baseData = [];
      var file = this.selectedFile;
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener("load", () => {
        imageUrl = fr.result;
        newUrl = imageUrl;
        newUrl = imageUrl.replace("data:" + file.type + ";base64,", "");

        baseData.push(newUrl);

        this.upload_S3(baseData[0], file);
      });
    },
    async upload_S3(baseData, file) {
      var data = this.$store.getters.get_user_details[0].cabinetdetails;
      let uint8array = new Uint8Array(JSON.parse(data));
      // ENCODER

      var array = new TextDecoder("utf-8").decode(uint8array);
      this.asseceskey = JSON.parse(array);
      var buf = Buffer.from(baseData, "base64");

      this.overlay = true;

      var buf = Buffer.from(baseData, "base64");

      var s3Bucket = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: this.asseceskey.access_key,
        secretAccessKey: this.asseceskey.secret_key,
      });
      // var bucketName = this.asseceskey.bucket_name;
      var bucketName = "przncebanners";
      var params = {
        Bucket: bucketName,
        Key: "banner" + "/" + Date.now() + "_" + file.name.split(" ").join(""),

        ACL: "public-read",
        Body: buf,
        ContentType: file.type,
      };

      var bucketurl =
        "https://" +
        bucketName +
        ".s3." +
        "ap-south-1" +
        ".amazonaws.com/" +
        params.Key;

      await s3Bucket.putObject(
        params,
        function (err, data) {
          if (err) {
            this.loading = false;
            // console.log(err);
          } else if (data) {
            this.loading = false;

            this.urlupload = bucketurl;
            this.saveAction();
          }
        }.bind(this)
      );
    },
  },
};
</script>
<style scoped>
.image-container {
  position: relative;
  display: inline-block;
}

.delete-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: -20px; /* Adjust margin as needed */
}
label {
  display: block;
  margin-top: -4px;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
</style>