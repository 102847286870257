<template>
  <div>
    <v-card flat>
      <v-toolbar dense elevation="0" class="mt-5">
        <div class="mr-10"><h3>Scan Credit</h3></div>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-row class="text-left">
          <v-col cols="6">
            <v-card>
              <v-card-text>
                <v-row class="mt-2" align="center">
                  <span
                    class="font-weight-medium ml-3"
                    style="font-size: medium"
                  >
                    <b>QR :</b>
                  </span>
                  <span v-if="!editMode" class="ml-2">{{ qr_scan }}</span>
                  <v-text-field
                    outlined
                    dense
                    v-if="editMode"
                    style="max-width: 60px"
                    v-model="qr_scan"
                    class="caption font-weight-medium ml-2 mt-2"
                    @keypress="is_number($event)"
                    v-on:paste="process($event)"
                  />
                </v-row>
                <br />

                <v-row class="mt-2" align="center">
                  <span
                    class="font-weight-medium ml-3"
                    style="font-size: medium"
                  >
                    <b>Simple :</b>
                  </span>
                  <span v-if="!editMode" class="ml-2">{{ simple_scan }}</span>
                  <v-text-field
                    outlined
                    dense
                    v-if="editMode"
                    style="max-width: 60px"
                    v-model="simple_scan"
                    class="caption font-weight-medium ml-2 mt-2"
                    @keypress="is_number($event)"
                    v-on:paste="process($event)"
                  />
                </v-row>
                <br />

                <v-row class="mt-2" align="center">
                  <span
                    class="font-weight-medium ml-3"
                    style="font-size: medium"
                  >
                    <b>Face :</b>
                  </span>
                  <span v-if="!editMode" class="ml-2">{{ face_scan }}</span>
                  <v-text-field
                    outlined
                    dense
                    v-if="editMode"
                    style="max-width: 60px"
                    v-model="face_scan"
                    class="caption font-weight-medium ml-2 mt-2"
                    @keypress="is_number($event)"
                    v-on:paste="process($event)"
                  />
                </v-row>
                <br />

                <v-row class="mt-2" align="center">
                  <span
                    class="font-weight-medium ml-3"
                    style="font-size: medium"
                  >
                    <b>Proxy :</b>
                  </span>
                  <span v-if="!editMode" class="ml-2">{{ proxy_scan }}</span>
                  <v-text-field
                    outlined
                    dense
                    v-if="editMode"
                    style="max-width: 60px"
                    v-model="proxy_scan"
                    class="caption font-weight-medium ml-2 mt-2"
                    @keypress="is_number($event)"
                    v-on:paste="process($event)"
                  />
                </v-row>
                <br />

                <v-row class="mt-2" align="center">
                  <span
                    class="font-weight-medium ml-3"
                    style="font-size: medium"
                  >
                    <b>Beacon :</b>
                  </span>
                  <span v-if="!editMode" class="ml-2">{{ Beacon_scan }}</span>
                  <v-text-field
                    outlined
                    dense
                    v-if="editMode"
                    style="max-width: 60px"
                    v-model="Beacon_scan"
                    class="caption font-weight-medium ml-2 mt-2"
                    @keypress="is_number($event)"
                    v-on:paste="process($event)"
                  />
                </v-row>
                <br />
              </v-card-text>

              <v-card-actions class="justify-end">
                <v-btn text @click="toggleEdit" class="ml-2 mt-n1" small>
                  <v-icon color="primary">mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  @click="get_mutation()"
                  :loading="buttonload"
                  class="elevation-0 gradient-bg white--text mr-2 mb-2"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qr_scan: 1,
      simple_scan: 1,
      face_scan: 1,
      proxy_scan: 1,
      Beacon_scan: 1,
      buttonload: false,

      editMode: false,
    };
  },
  methods: {
    toggleEdit() {
      this.editMode = !this.editMode;
    },
    is_number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    process(event) {
      if (event.type == "paste") {
        var clipboardData = event.clipboardData || window.clipboardData;
        var pastedData = clipboardData.getData("Text");
        if (isNaN(pastedData)) {
          event.preventDefault();
        } else {
          return;
        }
      }
      var keyCode = event.keyCode || event.which;
      if (keyCode >= 96 && keyCode <= 105) {
        keyCode -= 48;
      }
      var charValue = String.fromCharCode(keyCode);
      if (isNaN(parseInt(charValue)) && event.keyCode != 8) {
        event.preventDefault();
      }
    },
    get_mutation() {
      if (
        this.qr_scan == "" ||
        this.simple_scan == "" ||
        this.face_scan == "" ||
        this.proxy_scan == "" ||
        this.Beacon_scan == ""
      ) {
        alert(5);
      } else {
        alert(6);
      }
    },
  },
};
</script>

<style>
</style>