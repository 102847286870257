<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-data-table
          :headers="organisationData"
          :items="itemstablebanner"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :height="height"
          :search="search"
          :items-per-page="100"
          :loading="isLoading"
        >
          <template v-slot:[`item.Actions`]="{ item }">
            <v-icon small color="orange" @click.stop="view_dailog(item)">
              mdi-account-reactivate-outline
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      search: "",
      fixed: true,
      height: 0,
      reactiveitems:{},
      itemstablebanner: [{ banner_name: "sss" }],
      organisationData: [
        {
          text: "Name",
          value: "banner_name",

          sortable: false,
        },
        {
          text: "Organisation",
          value: "banner_description",

          sortable: false,
        },
        {
          text: "Deactivated On",
          value: "banner_created_by",

          sortable: false,
        },

        {
          text: "Actions",
          value: "Actions",
          tooltip_text: "Actions",
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    this.height = window.innerHeight - 210;
  },
  methods: {
    view_dailog(item) {
      console.log(item);
    },
  },
};
</script>

<style>
</style>