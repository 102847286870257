<template>
  <div>
    <!-- eslint-disable -->
    <v-dialog v-model="uploadFileDialog" persistent max-width="450">
      <v-card>
        <v-toolbar dense class="elevation-0 gradient-bg white--text">
          <v-toolbar-title class="text--white ml-2"
            >Upload File</v-toolbar-title
          >
          <v-spacer />
          <v-icon @click="close_dialog()" class="white--text">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-row no-gutters class="mt-5">
              <v-col cols="12">
                <v-file-input
                  outlined
                  dense
                  v-model="inputFiles"
                  :rules="[(v) => !!v || 'File is required']"
                  label="Choose File"
                  accept=".pdf, .jpg, .jpeg, .png"
                  @change="get_files(inputFiles)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            dark
            @click="validate_data()"
            :loading="loading"
            class="gradient-bg white--text mr-2 mb-2"
            >Upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable */
var AWS = require("aws-sdk");
import { Buffer } from "buffer";
export default {
  props: {
    uploadFileDialog: Boolean,
    orgResponse: Object,
    primaryKey: String,
  },
  data() {
    return {
      inputFiles: null,
      loading: false,
      selectedFile: {},
      fileRules: [(v) => !!v || "File is required"],
    };
  },
  methods: {
    close_dialog() {
      this.inputFiles = null;
      this.$emit("clicked", 0);
      this.$refs.form.reset();
    },

    get_files(file) {
      this.selectedFile = file;
    },

    validate_data() {
      if (this.$refs.form.validate()) {
        this.upload_file();
      }
    },
    upload_file() {
      this.loading = true;
      var imageUrl = [];
      var newUrl = [];
      var baseData = [];
      var file = this.selectedFile;
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener("load", () => {
        imageUrl = fr.result;
        newUrl = imageUrl;
        newUrl = imageUrl.replace("data:" + file.type + ";base64,", "");
        baseData.push(newUrl);
        this.upload_S3(baseData[0], file);
      });
    },
    async upload_S3(baseData, file) {
      var self = this;

      var buf = Buffer.from(baseData, "base64");
      var data = this.$store.getters.get_user_details[0].cabinetdetails;
      let uint8array = new Uint8Array(JSON.parse(data));
      // ENCODER

      var array = new TextDecoder("utf-8").decode(uint8array);
      this.asseceskey = JSON.parse(array);
      var s3Bucket = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: this.asseceskey.access_key,
        secretAccessKey: this.asseceskey.secret_key,
      });
      var bucketName = this.asseceskey.bucket_name;
      var region1 = "ap-south-1";
      var params = {
        Bucket: bucketName,
        Key: this.primaryKey != "" ? this.primaryKey + file.name : "/",
        Body: buf,
      };
      // console.log(params);

      var bucketurl =
        "https://" +
        bucketName +
        ".s3." +
        region1 +
        ".amazonaws.com/" +
        params.Key +
        "/" +
        Math.floor(new Date().getTime()) +
        "/" +
        file.name;

      await s3Bucket.putObject(params, function (err, data) {
        if (err) {
          this.loading = false;
        } else if (data) {
          self.loading = false;
          console.log(bucketurl);
          self.$emit("fileSuccess", "Uploaded Successfully");
          self.$refs.form.reset();
          self.$refs.form.resetValidation();
          self.inputFiles = null;
        }
      });
    },
  },
};
</script>