<template>
  <div>
    <v-dialog v-model="bannerDeletion" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title>Delete Banner</v-toolbar-title><v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div class="mt-5">
            Would you like to delete the {{ banneritems.banner_name }} banner?
          </div>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="">
            <v-btn
              color="primary"
              class="white--text"
              @click="close_dialog()"
              small
              >No</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              @click="delete_freeforms()"
              small
              >Yes</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
      <script>
import { API, graphqlOperation } from "aws-amplify";
import { delete_banner } from "@/graphql/mutations.js";
export default {
  components: {},
  props: {
    bannerDeletion: Boolean,
    banneritems: Object,
  },
  data: () => ({}),
  watch: {
    bannerDeletion: {
      handler() {
        {
          console.log(this.banneritems);
        }
      },
      immediate: true,
    },
  },
  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
    },
    async delete_freeforms() {
      this.loading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(delete_banner, {
            input: {
              banner_id: this.banneritems.banner_id,
              user_email_id: this.$store.getters.get_useremail,
            },
          })
        );
        // console.log(result);
        var response = JSON.parse(result.data.delete_banner);
        this.loading = false;
        if (response.Status == "SUCCESS") {
          this.$emit("successMsg", response.Message);
          this.loading = false;
        } else {
          this.$emit("errorMsg", response.Message);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
        this.loading = false;
      }
    },
  },
};
</script>