<template>
    <div>
      <v-card flat v-if="formActionType != 'initiate'">
        <!-- :flat="formActionType != undefined" -->
        <v-card-text>
          <v-row>
            <v-col cols="11">
              <v-textarea
                v-model="textValue"
                dense
                outlined
                readonly
                :label="
                  index.displayLabel
                "
                rows="0"
                auto-grow
                @click="emit_edit_field(index)"
              ></v-textarea>
            </v-col>
            <v-col cols="1">
              <v-btn
                v-if="
                  formActionType != 'initiate' &&
                  formActionType != 'preview' &&
                  formActionType != 'previewproof'
                "
                x-small
                color="red"
                fab
                dark
                absolute
                @click="emit_fields((Toggle = 1), index)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </template>
  <script>
  export default {
    props: {
      index: Object,
      formActionType: String,
    },
    data: () => ({
      Toggle: 0,
      textValue: "",
    }),
    created() {
      if (this.formActionType == "previewproof") {
        this.textValue = "";
        this.textValue = this.index.value == null ? "N/A" : this.index.value;
      }
    },
    beforeUpdate() {
      this.textValue = "";
      if (this.formActionType == "previewproof") {
        this.textValue = this.index.value == null ? "N/A" : this.index.value;
      }
    },
    methods: {
      emit_fields(Toggle, index) {
        this.$emit("clicked", Toggle, index);
      },
      emit_edit_field(value) {
        this.$emit("editField", value);
      },
    },
  };
  </script>