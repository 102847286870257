<template>
  <div>
    <!-- eslint-disable -->
    <v-dialog v-model="createFolderDialog" persistent max-width="450">
      <v-card>
        <v-toolbar class="elevation-0 gradient-bg white--text" dense>
          <v-toolbar-title class="text--white ml-2"
            >Create Folder</v-toolbar-title
          >
          <v-spacer />
          <v-icon @click="close_dialog()" class="white--text">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-row no-gutters class="mt-5">
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  :rules="[(v) => !!v || 'Required']"
                  v-model="folderName"
                  label="Folder Name"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            dark
            @click="validate_data()"
            :loading="loading"
            class="gradient-bg white--text mr-2 mb-2"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import AWS from "aws-sdk";
export default {
  props: {
    createFolderDialog: Boolean,
    orgResponse: Object,
    primaryKey: String,
  },
  watch: {
    createFolderDialog: {
      async handler() {
        if (this.createFolderDialog == true) {
          //   this.$refs.form.reset();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      folderName: "",
      loading: false,
    };
  },
  methods: {
    close_dialog() {
      this.folderName = "";
      this.$emit("clicked", 0);
    },
    validate_data() {
      if (this.$refs.form.validate()) {
        this.create_folder();
      }
    },
    create_folder() {
      var self = this;
      var data = this.$store.getters.get_user_details[0].cabinetdetails;
      let uint8array = new Uint8Array(JSON.parse(data));
      // ENCODER

      var array = new TextDecoder("utf-8").decode(uint8array);
      this.asseceskey = JSON.parse(array);
      const s3 = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: this.asseceskey.access_key,
        secretAccessKey: this.asseceskey.secret_key,
      }); // Replace with your S3 region
      const params = {
        Bucket:  this.asseceskey.bucket_name,
        Key:
          this.primaryKey != ""
            ? this.primaryKey +this.folderName + "/" 
            : "/",
        Body: "",
      };
// console.log(params);
      s3.upload(params, (err, data) => {
        if (err) {
          self.loading = false;
        } else {
          self.$emit("folderSuccess", "Created Successfully");
          self.folderName = "";
          self.loading = false;
        }
      });
    },
  },
};
</script>