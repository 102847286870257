<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              outlined
              dense
              v-model="Content_type"
              label="Name"
              :rules="[(v) => !!v || 'Required']"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              dense
              v-model="description_type"
              label="Description"
              :rules="[(v) => !!v || 'Required']"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn>Publish</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      description_type: "",
      Content_type: "",
    };
  },
};
</script>

<style>
</style>