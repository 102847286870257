<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-toolbar flat>
          <v-toolbar-title>
            <div class="mr-10"><h3>Banner</h3></div></v-toolbar-title
          >

          <v-spacer />
          <v-btn
            @click="addBanner()"
            v-if="itemstablebanner.length < 5"
            class="gradient-bg white--text"
            >Create</v-btn
          >
        </v-toolbar>
        <v-data-table
          :headers="organisationData"
          :items="itemstablebanner"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :height="height"
          :search="search"
          :items-per-page="100"
          :loading="isLoading"
        >
          <template v-slot:[`item.banner_description`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="caption" v-on="on">
                  {{
                    item.banner_description.length >= 50
                      ? item.banner_description.substr(0, 50) + "..."
                      : item.banner_description
                  }}
                </div>
              </template>
              <div class="textWrap white--text" style="max-width: 250px">
                {{ item.banner_description }}
              </div>
            </v-tooltip>
          </template>
          <template v-slot:[`item.banner_routing_url`]="{ item }">
            <a :href="item.banner_routing_url" target="_blank">
              {{ item.banner_routing_url }}
            </a>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  color="orange"
                  class="mr-2"
                  @click.stop="view_dailog(item)"
                >
                  mdi-information-variant-circle-outline
                </v-icon>
              </template>
              <span class="white--text">view</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  color="orange"
                  class="mr-2"
                  @click.stop="editdailog(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span class="white--text">view</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  color="red"
                  v-on="on"
                  class="mr-2"
                  @click.stop="delete_dailog(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span class="white--text">Delete</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.banner_created_on`]="{ item }">
            <span>{{ get_date(item.banner_created_on) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div v-if="componentCheck == 1">
      <BannerView
        :viewBanner="viewBanner"
        @clicked="viewBanner = false"
        :banneritems="banneritems"
      />
    </div>
    <div v-if="componentCheck == 2">
      <CreateBanner
        :bannerCreation="bannerCreation"
        @clicked="bannerCreation = false"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
      />
    </div>
    <div v-if="componentCheck == 3">
      <DeletBanner
        :bannerDeletion="bannerDeletion"
        @clicked="bannerDeletion = false"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
        :banneritems="banneritems"
      />
    </div>
    <div v-if="componentCheck == 4">
      <EditBanner
        :bannerEdition="bannerEdition"
        @clicked="bannerEdition = false"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
        :banneritems="banneritems"
      />
    </div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import EditBanner from "@/components/Dialogs/EditBanner.vue";
import DeletBanner from "@/components/Dialogs/DeletBanner.vue";
import Snackbar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { list_banners } from "@/graphql/queries.js";
import BannerView from "@/components/Dialogs/BannerView.vue";
import CreateBanner from "@/components/Dialogs/CreateBanner.vue";
export default {
  components: {
    BannerView,
    Snackbar,
    Overlay,
    CreateBanner,
    DeletBanner,
    EditBanner,
  },
  data() {
    return {
      itemstablebanner: [],
      SnackBarComponent: {},
      bannerEdition: false,
      height: 0,
      search: "",
      overlay:false,
      bannerDeletion: false,
      bannerCreation: false,
      isLoading: false,
      viewBanner: false,
      componentCheck: 0,
      banneritems: {},
      fixed: true,
      organisationData: [
        {
          text: "Name",
          value: "banner_name",

          sortable: false,
        },
        {
          text: "Description",
          value: "banner_description",

          sortable: false,
        },
        {
          text: "Created By",
          value: "banner_created_by",

          sortable: false,
        },
        {
          text: "Created On",
          value: "banner_created_on",

          sortable: false,
        },

        {
          text: "Url",
          value: "banner_routing_url",
          sortable: false,
        },

        {
          text: "Actions",
          value: "Actions",
          tooltip_text: "Actions",
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    this.height = window.innerHeight - 210;
    await this.listbanner();
  },
  methods: {
    success_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
      this.itemstablebanner = [];
      this.bannerCreation = false;
      this.bannerDeletion = false;
      this.bannerEdition = false;
      this.listbanner();
    },
    error_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },
    editdailog(item) {
      this.componentCheck = 4;
      this.banneritems = item;
      this.bannerEdition = true;
    },
    delete_dailog(item) {
      this.componentCheck = 3;
      this.banneritems = item;
      this.bannerDeletion = true;
    },
    addBanner() {
      this.componentCheck = 2;
      this.bannerCreation = true;
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    view_dailog(item) {
      this.componentCheck = 1;
      this.banneritems = item;
      this.viewBanner = true;
    },
    async listbanner() {
      this.isLoading = true;
      this.overlay = true;
      

      try {
        let result = await API.graphql(
          graphqlOperation(list_banners, {
            input: {
              user_email_id: this.$store.getters.get_useremail,
            },
          })
        );

        this.isLoading = false;
        this.overlay = false;

        var response = JSON.parse(result.data.list_banners);
        if (response.Status == "SUCCESS") {
          this.itemstablebanner = response.data;
          this.overlay = false;
        }
        this.MembersItems = JSON.parse(result.data.list_banners).data;
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
      }
    },
  },
};
</script>

<style>
</style>