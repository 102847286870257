<template>
  <div>
    <v-card flat>
      <v-toolbar flat>
        <v-spacer />
        <v-btn @click="add_forms()" small class="gradient-bg white--text">
          Create Form
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :height="height"
          class="overflow-y-auto overflow"
          dense
          :headers="MembersDataActive"
          :items="MembersItems"
          :items-per-page="15"
          :fixed-header="fixed"
          :loading="isLoadingMember"
          loading-text="Loading... Please wait"
          @click:row="handleClick"
        >
          <template v-slot:no-data>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No Data available.
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>

          <template v-slot:[`item.form_created_on`]="{ item }">
            <span v-text="get_date(item.form_created_on)"></span>
          </template>
          <template v-slot:[`item.attachments_supports`]="{ item }">
            <span v-if="item.attachments_supports == 'CAMERA_ONLY'">
              Camera</span
            >
            <span v-else-if="item.attachments_supports == 'GALLERY_ONLY'">
              Gallery</span
            >
            <span v-else>Camera & Gallery</span>
          </template>
          <template v-slot:[`item.is_free_text`]="{ item }">
            <span v-if="item.is_free_text == true">Yes</span>
            <span v-else> No</span>
          </template>
          <template v-slot:[`item.form_description`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">
                  {{
                    item.form_description.length >= 10
                      ? item.form_description.substr(0, 10) + "..."
                      : item.form_description
                  }}
                </div>
              </template>
              <span class="white--text">{{ item.form_description }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              @click.stop="edit_forms(item)"
              color="primary"
              class="mr-2"
            >
              mdi-pencil</v-icon
            >
            <v-icon small @click.stop="delet_forms(item)" color="red"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <div v-if="componentCheck == 2">
      <ForemsProofwork
        :Dynamicform="Dynamicform"
        @clicked="Dynamicform = false"
        :namesobj="namesobj"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
        @closedialog="call"
      />
    </div>
    <div v-if="componentCheck == 3">
      <DeleteForms
        :Formdeletion="Formdeletion"
        :formitems="formitems"
        @clicked="Formdeletion = false"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
      />
    </div>
    <div v-if="componentCheck == 4">
      <FormPreview
        :previewDialog="previewDialog"
        :formFields="formFields"
        @clicked="previewDialog = false"
      />
    </div>
    <div v-if="componentCheck == 5">
      <EditForms
        :editforms="editforms"
        :formitems="formitems"
        @clicked="checking"
      />
    </div>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import EditForms from "@/components/Dialogs/EditForms.vue";
import DeleteForms from "@/components/Dialogs/DeleteForms.vue";
import { API, graphqlOperation } from "aws-amplify";
import { list_forms_library } from "@/graphql/queries.js";
import ForemsProofwork from "@/components/Dialogs/ForemsProofwork.vue";

import SnackBar from "@/components/SnackBar.vue";
import FormPreview from "@/components/Dialogs/FormPreview.vue";
export default {
  components: {
    ForemsProofwork,
    SnackBar,
    DeleteForms,
    FormPreview,
    Overlay,
    EditForms,
  },
  data() {
    return {
      isLoadingMember: false,
      previewDialog: false,
      formFields: [],
      formitems: {},
      Createforms: false,
      editforms: false,
      overlay: false,
      MembersItems: [],
      nextToken: null,
      SnackBarComponent: {},
      fixed: true,
      Formdeletion: false,
      height: 0,
      Dynamicform: false,
      namesobj: {},
      componentCheck: 0,
      MembersDataActive: [
        {
          text: "Form Name",
          value: "form_name",
          fixed: true,
          sortable: false,
        },
        {
          text: "Form Desciption",
          value: "form_description",
          fixed: true,
          sortable: false,
        },

        {
          text: "Created By",
          value: "form_created_by",
          fixed: true,
          sortable: false,
        },
        {
          text: "Created On",
          value: "form_created_on",
          fixed: true,
          sortable: false,
        },
        {
          text: "Status",
          value: "attachments_supports",
          fixed: true,
          sortable: false,
        },

        {
          text: "Action",
          value: "actions",
          fixed: true,
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.height = window.innerHeight - 230;

    this.list_forms_view();
  },
  methods: {
    call(val) {
      if (val == 0) {
        this.Dynamicform = false;
        this.list_forms_view();
      }
    },
    checking() {
      this.editforms = false;
      this.list_forms_view();
    },
    get_date(date) {
      // console.log(date, "data");
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      // console.log(a);
      return a.toUpperCase();
    },
    async list_forms_view() {
      this.isLoadingMember = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(list_forms_library, {
            input: {
              // organisation_id:
              //   this.$store.getters.get_org_details[0].organisation_id,
              limit: 50,
              nextToken: this.nextToken,
            },
          })
        );

        this.isLoadingMember = false;
        this.overlay = false;
        console.log(result);
        this.MembersItems = JSON.parse(result.data.list_forms_library).data;
      } catch (err) {
        console.log("error", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.isLoadingMember = false;
        this.overlay = false;
      }
    },
    edit_forms(item) {
      this.formitems = item;
      this.componentCheck = 5;
      this.editforms = true;
    },
    delet_forms(item) {
      this.formitems = item;
      this.componentCheck = 3;
      this.Formdeletion = true;
    },
    add_forms() {
      this.componentCheck = 2;
      this.Dynamicform = true;
    },
    nextstep() {
      this.Createforms = false;
      this.componentCheck = 2;
      this.Dynamicform = true;
      this.namesobj;
    },
    store(formobject) {
      this.namesobj = formobject;
    },

    error_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },
    handleClick(value) {
      this.componentCheck = 4;
      this.previewDialog = true;
      this.formFields = value.form_desgin;
    },

    success_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
      this.Createforms = false;
      this.Formdeletion = false;
      this.Dynamicform = false;
      this.list_forms_view();
    },
  },
};
</script>

<style>
</style>