<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-row class="ma-1">
      <v-col cols="12" md="6" sm="6" lg="6" xl="6">
        <v-hover v-slot="{ hover }" open-delay="200">
        <v-card class="py-10 pl-5  rounded-xl" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
          <div class="text-center primary--text largeFontSize mt-n5">
            My Prezence
          </div>
              <v-row class="mt-4">
                <v-col cols="12" md="12" sm="12" lg="12" xl="12" >
              <label class="font-weight-medium">Android Version</label>
              </v-col>
              </v-row>
             <v-row class="mt-n5">
            <v-col cols="12" md="2" sm="3" lg="2" xl="2" >
              <v-text-field outlined dense v-model="Android_version" class="caption font-weight-medium"></v-text-field>
              
              <!-- Android_version(this name is given by me) -->

              <!-- <v-text-field outlined dense v-model="versionDetails.Version.Android.version"></v-text-field> -->

              <!-- <v-btn disabled small depressed tile>{{
                versionDetails.Version
                  ? versionDetails.Version.Android.version
                  : ""
              }}</v-btn> -->
            </v-col>
          </v-row>
        
          <v-row class="mt-n6">
            <v-col cols="12" md="12" sm="12" lg="12" xl="12" >
              <label class="font-weight-medium">Android link</label>
              </v-col>
              </v-row>
              <v-row class="mt-n5">
            <v-col cols="12" md="11" sm="11" lg="11" xl="11" >
              <v-text-field outlined dense v-model="android_link" class="caption font-weight-medium"></v-text-field>
              <!-- <v-text-field outlined dense v-model="versionDetails.Version.Android.urllink"></v-text-field> -->

              <!-- <v-btn small depressed tile class="FontSize"
                ><span class="FontSize">{{
                  versionDetails.Version
                    ? versionDetails.Version.Android.urllink
                    : ""
                }}</span></v-btn
              > -->
            </v-col>
          </v-row>
          <v-row class="mt-n6">
            <v-col cols="12" md="12" sm="12" lg="12" xl="12">
              <!-- <v-btn small depressed tile class="">{{
                versionDetails.Version ? versionDetails.Version.IOS.version : ""
              }}</v-btn> -->
              <label class="font-weight-medium">IOS Version</label>
              </v-col>
              </v-row>
              <v-row class="mt-n5">
            <v-col cols="12" md="2" sm="3" lg="2" xl="2" >
              <v-text-field dense outlined v-model="ios_version" class="caption font-weight-medium"></v-text-field>
              <!-- <v-text-field dense outlined v-model="versionDetails.Version.IOS.version "></v-text-field> -->
            </v-col>
          </v-row>
          <v-row class="mt-n6">
            <v-col cols="12" md="12" sm="12" lg="12" xl="12">
              <label class="font-weight-medium">IOS link</label>
              </v-col>
              </v-row>
              <v-row class="mt-n5">
            <v-col cols="12" md="9" sm="9" lg="9" xl="9" >
              <v-text-field outlined dense v-model="ios_link" class="caption font-weight-medium"></v-text-field>
              <!-- <v-text-field outlined dense  v-model="versionDetails.Version.IOS.urllink"></v-text-field> -->
              <!-- <v-btn depressed x-small tile class="FontSize">{{
                adminVersionDetails.Version
                  ? versionDetails.Version.IOS.urllink
                  : ""
              }}</v-btn> -->
            </v-col>
          </v-row>
          <div class="text-center">
            <v-btn @click="fetch_mutation()" color="primary" class="mt-n2 text-center white--text">Update</v-btn>
          </div>
        </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="6" sm="6" lg="6" xl="6">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card class="py-10 pl-3 rounded-xl" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
            <div class="text-center primary--text largeFontSize mt-n5">
              Prezence
            </div>
            <v-row class="mt-4">
              <v-col cols="12" md="12" sm="12" lg="12" xl="12" >
                <label class="font-weight-medium ">Android Version</label>
                </v-col>
                </v-row>
                <!-- <v-btn small depressed tile>{{
                adminVersionDetails.Version
                  ? versionDetails.Version.Android.version
                  : ""
              }}</v-btn> -->
              <v-row class="mt-n5">
              <v-col cols="12" md="2" sm="3" lg="2" xl="2">
                <v-text-field outlined dense v-model="new_version" class="caption font-weight-medium"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col cols="12"  md="12" sm="12" lg="12" xl="12"  >
                <label class="font-weight-medium">Android link</label>
                </v-col>
                </v-row>
                <v-row class="mt-n5">
              <v-col cols="12"  md="10" sm="10" lg="10" xl="10"  >
                <v-text-field outlined dense v-model="phone_link" class="caption font-weight-medium"></v-text-field>
                <!-- <v-btn small depressed tile>{{
                adminVersionDetails.Version
                  ? versionDetails.Version.Android.urllink
                  : ""
              }}</v-btn> -->
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col cols="12" md="12" sm="12" lg="12" xl="12">
                <label class="font-weight-medium">IOS Version</label>
                <!-- <v-btn small depressed tile>{{
                adminVersionDetails.Version
                  ? versionDetails.Version.IOS.version
                  : ""
              }}</v-btn> -->
              </v-col>
              </v-row>
              <v-row class="mt-n5">
              <v-col cols="12"  md="2" sm="3" lg="2" xl="2"  >
                <v-text-field outlined dense v-model="iosphone_version" class="caption font-weight-medium"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col cols="12"  md="12" sm="12" lg="12" xl="12"  >
                <label class="font-weight-medium">IOS link</label>
                </v-col>
                </v-row>
                <v-row class="mt-n5">
              <v-col cols="12"  md="9" sm="9" lg="9" xl="9"  >
                <v-text-field outlined dense v-model="iosphone_link" class="caption font-weight-medium"></v-text-field>
              </v-col>
            </v-row>
            <div class="text-center">
              <v-btn color="primary" class="mt-4 text-center white--text mt-n2" @click="get_details_prezence()">Update</v-btn>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { updateAdminAppVersion } from "@/graphql/mutations.js";
import { updateMyPreznenceAppVersion } from "@/graphql/mutations.js";
import { GetPrezenceVersion } from "@/mixins/getprezncelatestversion.js";
import { GetAdminlatestVersion } from "@/mixins/getAdminLatestVersion.js";
export default {
  components: {
    Overlay,
    SnackBar,
  },
  mixins: [GetPrezenceVersion, GetAdminlatestVersion],
  data: () => ({
    overlay: false,
    Android_version: "", //this is the v-model givn in text name
    android_link: "",
    SnackBarComponent: {},
    ios_version: "",
    ios_link: "",
    new_version: "",
    phone_link: "",
    iosphone_version: "",
    iosphone_link: "",

  }),
  async mounted() {
    await this.GetPrezenceVersionMethod();
    await this.GetPrezenceAdminVersionMethod();
    // console.log(this.versionDetails,"My Prezence")
    this.Android_version = this.versionDetails.Version.Android.version;
    this.ios_version = this.versionDetails.Version.IOS.version;
    this.android_link = this.versionDetails.Version.Android.urllink;
    this.ios_link = this.versionDetails.Version.IOS.urllink;



    this.new_version = this.adminVersionDetails.Version.Android.version;
    this.phone_link = this.adminVersionDetails.Version.Android.urllink;
    this.iosphone_version = this.adminVersionDetails.Version.IOS.version;
    this.iosphone_link = this.adminVersionDetails.Version.IOS.urllink;
    // console.log("test", this.adminVersionDetails)

    // this.new_version=this.
    this.overlay = true;
    setTimeout(() => {
      this.overlay = false;
    }, 1000);
  },
  methods: {
    RouteMethod() {
      // let URL = this.versionDetails.Version.Android.url;
      window.location.assign(`${this.versionDetails.Version.Android.url}`);
    },
    // fetch_mutation()--this is attached to the update button
    async fetch_mutation() {
      try {
        let result = await API.graphql(
          graphqlOperation(updateMyPreznenceAppVersion, {
            input: {
              Androidversion: this.Android_version, //Androidversion-this is schema.json &Android_version-name given by me in text field.
              iosversion: this.ios_version,
              androidlink: this.android_link,
              ioslink: this.ios_link,

            },
          })
        );
        this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                Top: true,
                SnackbarText:"updated successfully",
              };
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },


    async get_details_prezence() {
      try {
        let result = await API.graphql(
          graphqlOperation(updateAdminAppVersion, {
            input: {
              // Androidversion: this.new_version,
              // phonelink: this.phone_link,

              Androidversion: this.new_version,
              iosversion: this.iosphone_version,
              androidlink: this.phone_link,
              ioslink: this.iosphone_link,   
            },
          })
        );
        this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                Top: true,
                SnackbarText:"updated successfully",
              };
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },


  },
};
</script>