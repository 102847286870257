<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>
        <div class="mr-10"><h3>Cabinet</h3></div></v-toolbar-title
      >
      <v-spacer />
      <v-btn
        dark
        @click="create_folder()"
        class="gradient-bg white--text mr-2"
        v-if="breadcrumbsItems.length > 1"
      >
        <v-icon left> mdi-folder </v-icon> Create Folder</v-btn
      >
      <v-btn
        @click="upload_file()"
        dark
        class="gradient-bg white--text"
        v-if="breadcrumbsItems.length > 1"
      >
        <v-icon left> mdi-cloud-upload </v-icon>
        Upload File
      </v-btn>

      <!-- <v-btn
          dark
          @click="create_folder()"
          class="ml-2 text-capitalize cardCss mr-2"
        >
          <v-icon left> mdi-folder </v-icon> Create Folder</v-btn
        > -->
      <!-- <v-btn dark @click="add_data()" class="text-capitalize cardCss"
          >Create Folder</v-btn
        > -->
      <!-- <v-btn dark class="text-capitalize cardCss" @click="back_call()">
          <v-icon class="mr-2">mdi-step-backward</v-icon>
          Back
        </v-btn> -->
    </v-toolbar>
    <v-breadcrumbs class="mt-n2" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon color="primary">mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item, index }">
        <v-breadcrumbs-item @click="previous_data(item, breadcrumbsItems)">
          <!-- <v-icon v-if="item.name == 'MyDocs'" class="mr-1" color="primary"
            >mdi-home</v-icon
          > -->
          <div :style="{ color: isLastItem(item) ? '#f4813f' : '#000000' }">
            <b> {{ item.text }}</b>
          </div>
          <div>{{ index }}</div>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-card class="mt-n1" flat>
      <v-card-text>
        <SnackBar :SnackBarComponent="SnackBarComponent" />
        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="tableLoading"
          :height="height"
          :fixed-header="fixed"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 30, 40, 50],
          }"
          :items-per-page="20"
          dense
          class="overflow-y-auto overflow"
          @click:row="handleRowClick"
        >
          <template v-slot:[`item.FileName`]="{ item }">
            <v-icon color="black" small>{{ item.fileIcon }}</v-icon>
            <span class="caption ml-2">{{ item.FileName }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-icon
                v-if="item.fileIcon != 'mdi-folder'"
                small
                color="primaryColor"
                @click="view_image(item)"
              >
                mdi-eye
              </v-icon> -->
            <!--  -->
            <!-- v-if="item.fileIcon != 'mdi-folder'" -->
            <v-icon
              v-if="item.FileName != 'prznce' && item.FileName != 'myprznce'"
              :loading="loadingDel"
              small
              color="red"
              class="mr-2"
              @click.stop="delete_dailog(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon
              v-if="item.FileName != 'prznce' && item.FileName != 'myprznce'"
              :loading="loadingDel"
              v-show="item.fileIcon != 'mdi-folder'"
              small
              color="primaryColor"
              @click="download_actions(item)"
            >
              mdi-download
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="Formdeletion" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title>Delete {{ cabitem.FileName }} </v-toolbar-title
          ><v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div class="mt-5">
            Would you like to Delete <b> {{ cabitem.FileName }}</b> ?
          </div>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="">
            <v-btn
              color="primary"
              class="white--text"
              @click="close_dialog()"
              small
              >No</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              @click="delete_freeforms()"
              small
              >Yes</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <div v-if="componentCheck == 1">
      <CreateFolder
        :createFolderDialog="createFolderDialog"
        :primaryKey="primaryKey"
        :orgResponse="orgResponse"
        @clicked="createFolderDialog = false"
        v-on:folderSuccess="new_folder_creation"
      />
    </div>
    <div v-if="componentCheck == 2">
      <UploadFileDialog
        :uploadFileDialog="uploadFileDialog"
        :primaryKey="primaryKey"
        :orgResponse="orgResponse"
        @clicked="uploadFileDialog = false"
        v-on:fileSuccess="new_folder_creation"
      />
    </div>
    <div v-if="componentCheck == 3">
      <!-- <FileView
          :rowInfo="rowInfo"
          :imageView="imageView"
          :s3Details="s3Details"
          @clicked="imageView = false"
        /> -->
    </div>
  </div>
</template>
  <script>
/*eslint-disable*/
import UploadFileDialog from "@/components/Dialogs/UploadFileDialog.vue";
//   import { get_Org_details } from "@/mixins/GetOrgDetails.js";
import CreateFolder from "@/components/Dialogs/CreateFolder.vue";
//   import UploadFileDialog from "@/components/CollateralsApp/CollateralPopups/UploadFileDialog.vue";
//   import FileView from "@/components/CollateralsApp/CollateralPopups/FileView.vue";
import SnackBar from "@/components/SnackBar.vue";
import AWS from "aws-sdk";
import axios from "axios";
const FileSaver = require("file-saver");
export default {
  components: {
    SnackBar,
    CreateFolder,
    UploadFileDialog,
    //   FileView,
  },
  // mixins: [get_Org_details],
  data() {
    return {
      SnackBarComponent: {},
      Formdeletion: false,
      cabitem: {},
      headers: [
        { text: "Folder", value: "FileName", sortable: false },
        // {
        //   text: "Owner",
        //   value: "workflow_description",
        //   sortable: false,
        // },
        { text: "File Size", value: "FileSize", sortable: false },
        { text: "Last Modified", value: "LastModified", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
        // { text: "#Txs", value: "total_counts_of_workflows", sortable: false },
      ],
      tableData: [],
      newdata: [],
      tableLoading: false,
      orgResponse: {},
      createFolderDialog: false,
      componentCheck: 0,
      uploadFileDialog: false,
      loadingDel: false,
      breadcrumbs: [],
      firstCheck: 0,
      primaryKey: "",
      breadcrumbsItems: [],
      currentPathInfo: "",
      fixed: true,
      height: 0,
      imageView: false,
      rowInfo: {},
      s3Details: {},
      check: {},
      asseceskey: {},
      folderdelete: false,
    };
  },
  async created() {
    this.firstCheck = 0;
    this.height = window.innerHeight - 280;
    //   await this.get_Org_details();
    this.get_details();
    //   this.s3Details = this.orgDetails;
    this.breadcrumbsItems = this.dynamicBreadcrumbs;
    // console.log(this.breadcrumbsItems);
    // var data = this.$store.getters.get_user_details[0].cabinetdetails;
    // console.log(typeof data);
    // console.log(data);
    //  (data) => {
    //   let uint8array = new Uint8Array(JSON.parse(data));
    //   // ENCODER
    //   return new TextDecoder("utf-8").decode(uint8array);
    // };
    // let uint8array = new Uint8Array(JSON.parse(data));
    // // ENCODER

    // this.asseceskey = new TextDecoder("utf-8").decode(uint8array);
    // console.log(JSON.parse(array));
    // console.log(convert_from_byte_array);
    this.fetch_folders("dd");
  },
  computed: {
    dynamicBreadcrumbs() {
      const breadcrumbs = this.currentPathInfo.split("/").filter(Boolean);

      const firstBreadcrumb = {
        text: "My Docs",
        disabled: false,
        CurrentPath: "",
      };
      const dynamicBreadcrumbs = breadcrumbs.map((breadcrumb, index) => ({
        text: breadcrumb,
        disabled: index === breadcrumbs.length - 1, // Disable the last breadcrumb
      }));

      return [firstBreadcrumb, ...dynamicBreadcrumbs];
    },
  },
  // watch: {
  //   currentPathInfo() {
  //     this.breadcrumbsItems = this.dynamicBreadcrumbs;
  //   },
  // },
  methods: {
    isLastItem(currentItem) {
      // console.log(currentItem,'currentItem');
      const lastItem = this.breadcrumbsItems[this.breadcrumbsItems.length - 1];
      return currentItem.text === lastItem.text;
      // return currentItem;
    },
    back_call() {
      this.$router.push("/UserlevelApp");
    },
    close_dialog() {
      this.Formdeletion = false;
    },
    async fetch_folderstwo(value) {
      // console.log(value, "apicall");
      // console.log(value, " this.fetch_folders(item.CurrentPath);");

      var data = this.$store.getters.get_user_details[0].cabinetdetails;
      let uint8array = new Uint8Array(JSON.parse(data));
      // ENCODER

      var array = new TextDecoder("utf-8").decode(uint8array);
      this.asseceskey = JSON.parse(array);

      var self = this;
      this.newdata = [];
      var folders = [];
      var contents = [];
      const s3 = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: this.asseceskey.access_key,
        secretAccessKey: this.asseceskey.secret_key,
      }); //
      // console.log(s3);
      var prefixData = this.firstCheck == 1 ? value : "";
      this.primaryKey = prefixData;
      const params = {
        Bucket: this.asseceskey.bucket_name,
        Prefix: prefixData,
        Delimiter: "/",
      };
      // console.log(params, "params");
      this.check = params;
      return new Promise((resolve, reject) => {
        s3.listObjects(params, (err, data) => {
          if (err) {
            console.log(err);
            self.tableLoading = false;
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              SnackbarText: "Error Occured!! Please try again",
              Top: true,
            };
          } else {
            self.newdata = [];

            self.tableLoading = false;
            if (data.CommonPrefixes.length > 0) {
              data.CommonPrefixes.forEach(function (folder) {
                folders.unshift({
                  FileName: folder.Prefix.replace(params.Prefix, "").split(
                    "/"
                  )[0],
                  CurrentPath: folder.Prefix,
                  FileSize: 0,
                  LastModified: "-",
                  fileIcon: "mdi-folder",
                });
              });
            }

            if (data.Contents.length > 0) {
              contents = data.Contents.filter(
                (item) => item.Key !== params.Prefix
              ).map((item) => ({
                FileName: item.Key.replace(params.Prefix, ""),
                CurrentPath: item.Key,
                FileSize: this.formatBytes(item.Size),
                LastModified: new Date(item.LastModified).toLocaleString(),
                fileIcon: this.getFileIcon(item.Key),
              }));
            }
            this.newdata = [...folders, ...contents];

            // console.log(this.newdata.length, "delete");
            if (this.newdata.length == 0) {
              this.delete_action(this.cabitem);
            } else {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                SnackbarText:
                  "Delete the root files and folder, and delete this folder.",
                Top: true,
                timeout: 5000,
              };
            }
          }
        });
      });
    },
    async delete_freeforms() {
      this.Formdeletion = false;
      // console.log(this.cabitem, "this.cabitem");
      await this.fetch_folderstwo(this.cabitem.CurrentPath);

      // this.delete_action(this.cabitem);
    },
    get_details() {},
    create_folder() {
      this.createFolderDialog = true;
      this.componentCheck = 1;
      this.orgResponse = this.orgDetails;
    },
    upload_file() {
      this.uploadFileDialog = true;
      this.componentCheck = 2;
      this.orgResponse = this.orgDetails;
    },
    create_folder() {
      this.createFolderDialog = true;
      this.componentCheck = 1;
      this.orgResponse = this.orgDetails;
    },
    async fetch_folders(value) {
      // console.log(value, "apicall");
      // console.log(value, " this.fetch_folders(item.CurrentPath);");
      this.tableLoading = true;
      var data = this.$store.getters.get_user_details[0].cabinetdetails;
      let uint8array = new Uint8Array(JSON.parse(data));
      // ENCODER

      var array = new TextDecoder("utf-8").decode(uint8array);
      this.asseceskey = JSON.parse(array);

      var self = this;
      this.tableData = [];
      var folders = [];
      var contents = [];
      const s3 = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: this.asseceskey.access_key,
        secretAccessKey: this.asseceskey.secret_key,
      }); //
      // console.log(s3);
      var prefixData = this.firstCheck == 1 ? value : "";
      this.primaryKey = prefixData;
      const params = {
        Bucket: this.asseceskey.bucket_name,
        Prefix: prefixData,
        Delimiter: "/",
      };
      // console.log(params, "params");
      this.check = params;
      return new Promise((resolve, reject) => {
        s3.listObjects(params, (err, data) => {
          if (err) {
            console.log(err);
            self.tableLoading = false;
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              SnackbarText: "Error Occured!! Please try again",
              Top: true,
            };
          } else {
            self.tableData = [];

            self.tableLoading = false;
            if (data.CommonPrefixes.length > 0) {
              data.CommonPrefixes.forEach(function (folder) {
                folders.unshift({
                  FileName: folder.Prefix.replace(params.Prefix, "").split(
                    "/"
                  )[0],
                  CurrentPath: folder.Prefix,
                  FileSize: 0,
                  LastModified: "-",
                  fileIcon: "mdi-folder",
                });
              });
            }

            if (data.Contents.length > 0) {
              contents = data.Contents.filter(
                (item) => item.Key !== params.Prefix
              ).map((item) => ({
                FileName: item.Key.replace(params.Prefix, ""),
                CurrentPath: item.Key,
                FileSize: this.formatBytes(item.Size),
                LastModified: new Date(item.LastModified).toLocaleString(),
                fileIcon: this.getFileIcon(item.Key),
              }));
            }
            this.tableData = [...folders, ...contents];
            // console.log(this.tableData);
            if (this.folderdelete == true) {
              // console.log(this.tableData);
              // this.deleteAllItems(this.tableData);
              this.folderdelete = false;
              // console.log(value, "folder");
              var newurl = self.removeLastTwoSegments(value);
              // console.log(newurl);
              // console.log(newurl + "/", "new url");

              // this.fetch_folders(value.split("/")[0] + "/");
              this.fetch_folders(newurl + "/");
              // console.log(newurl + "/", "valuevalue");
            }
          }
        });
      });
    },
    async deleteAllItems(items) {
      // Iterate through each item in the array
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        // Call delete_action function for each item
        await this.delete_action(item);

        // Check if the delete_action function was successful before proceeding to the next item
        if (this.firstCheck === 1) {
          // Remove the item from the array
          items.splice(i, 1);

          // Decrement the index to handle the removed item
          i--;
        }
      }

      // At this point, all items in the array are deleted
      // You can now delete the entire array if needed
      items.length = 0;
    },
    view_image(item) {
      this.rowInfo = item;
      this.imageView = true;
      this.componentCheck = 3;
    },

    previous_data(item, allValues) {
      var index = null;
      index = this.breadcrumbsItems.findIndex((obj) => obj.text === item.text);
      const lastIndex = this.breadcrumbsItems.length - 1;
      const previousIndex = index + 1;
      if (lastIndex == previousIndex) {
        this.breadcrumbsItems.splice(previousIndex, 1);
        this.firstCheck = 1;
        this.fetch_folders(item.CurrentPath);
      }
    },

    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) {
        return "0 Bytes";
      }
      const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const base = 1024;
      const exponent = Math.floor(Math.log(bytes) / Math.log(base));
      const size = (bytes / Math.pow(base, exponent)).toFixed(2);

      return size + " " + units[exponent];
    },

    handleRowClick(value) {
      // console.log(value,'git commit -m "Initial commit"');
      if (value.FileSize == 0) {
        this.primaryKey = value.CurrentPath;
        this.breadcrumbsItems.push({
          text: "My Docs",
          disabled: false,
          CurrentPath: this.currentPathInfo,
          CurrentPath: value.CurrentPath,
          text: value.FileName,
          LastModified: 0,
          // fileIcon: "",
        });
        this.firstCheck = 1;
        this.fetch_folders(value.CurrentPath);
      } else if (
        value.FileSize == "0 Bytes" &&
        value.fileIcon == "mdi-folder"
      ) {
        this.primaryKey = value.CurrentPath;
        this.breadcrumbsItems.push({
          text: "My Docs",
          disabled: false,
          CurrentPath: this.currentPathInfo,
          CurrentPath: value.CurrentPath,
          text: value.FileName,
          LastModified: 0,
          // fileIcon: "",
        });
        this.firstCheck = 1;
        this.fetch_folders(value.CurrentPath);
      }
    },
    delete_dailog(item) {
      this.Formdeletion = true;
      (this.cabitem = {}), (this.cabitem = item);
    },
    async delete_action(item) {
      // console.log(item, "http://localhost:8082/ ");
      this.loadingDel = true;
      var data = this.orgDetails;
      var self = this;
      var data = this.$store.getters.get_user_details[0].cabinetdetails;
      let uint8array = new Uint8Array(JSON.parse(data));
      // ENCODER

      var array = new TextDecoder("utf-8").decode(uint8array);
      this.asseceskey = JSON.parse(array);
      var s3Bucket = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: this.asseceskey.access_key,
        secretAccessKey: this.asseceskey.secret_key,
      });
      var bucketName = this.asseceskey.bucket_name;
      var params = {
        Bucket: bucketName,
        Key: item.CurrentPath,
      };

      await s3Bucket.deleteObject(params, function (err, data) {
        if (err) {
          self.loadingDel = false;
        } else if (data) {
          self.loadingDel = false;
          this.firstCheck = 1;
          // console.log(item.CurrentPath, "First");
          if (item.fileIcon != "mdi-folder") {
            // console.log(item.CurrentPath, "image");
            let directoryPaths =
              item.CurrentPath.substring(0, item.CurrentPath.lastIndexOf("/")) +
              "/";
            // self.fetch_folders(item.CurrentPath.split("/")[0] + "/");
            self.fetch_folders(directoryPaths);
          } else {
            // var newurl = self.removeLastTwoSegments(item.CurrentPath);
            // console.log(newurl);
            // console.log(item.CurrentPath, "folder");
            self.folderdelete = true;
            self.fetch_folders(item.CurrentPath);
            // console.log(self.tableData);
            // self.tableData.length>=1
          }
        }
      });
    },
    removeLastTwoSegments(path) {
      let segments = path.split("/");

      // Ensure there are at least two segments to remove
      if (segments.length >= 2) {
        // Remove the last two segments
        segments.splice(-2, 2);

        // Join the remaining segments back into a path
        return segments.join("/");
      } else {
        // If there are fewer than two segments, return the original path
        return path;
      }
    },
    //     async delete_action_folder(item) {
    //       this.loadingDel = true;
    //       var data = this.orgDetails;
    //       var self = this;
    //       var data = this.$store.getters.get_user_details[0].cabinetdetails;
    //       var s3Bucket = new AWS.S3({
    //         region: "ap-south-1",
    //         accessKeyId: data.access_key,
    //         secretAccessKey: data.secret_key,
    //       });
    //       var bucketName = data.bucket_name;

    //       // Function to recursively delete items inside a folder
    //       async function deleteFolderContents(folderKey) {
    //         var listParams = {
    //           Bucket: bucketName,
    //           Prefix: folderKey,
    //         };

    //         var listedObjects = await s3Bucket.listObjectsV2(listParams).promise();

    //         if (listedObjects.Contents.length === 0) return;

    //         var deleteParams = {
    //           Bucket: bucketName,
    //           Delete: { Objects: [] },
    //         };

    //         listedObjects.Contents.forEach(({ Key }) => {
    //           deleteParams.Delete.Objects.push({ Key });
    //         });

    //         await s3Bucket.deleteObjects(deleteParams).promise();
    //       }

    //       var params = {
    //         Bucket: bucketName,
    //         Key: item.CurrentPath,
    //       };
    // console.log(item,'item');
    //       if (item.IsFolder) {
    //         // If it's a folder, delete its contents first
    //         await deleteFolderContents(item.CurrentPath);
    //       }

    //       // Now, delete the folder itself
    //       await s3Bucket.deleteObject(params, function (err, data) {
    //         if (err) {
    //           self.loadingDel = false;
    //         } else if (data) {
    //           self.loadingDel = false;
    //           this.firstCheck = 1;
    //           console.log(item.CurrentPath);
    //           self.fetch_folders(item.CurrentPath.split("/")[0] + "/");
    //         }
    //       });
    //     },

    async download_actions(item) {
      var data = this.$store.getters.get_user_details[0].cabinetdetails;
      let uint8array = new Uint8Array(JSON.parse(data));
      // ENCODER

      var array = new TextDecoder("utf-8").decode(uint8array);
      this.asseceskey = JSON.parse(array);
      const s3 = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: this.asseceskey.access_key,
        secretAccessKey: this.asseceskey.secret_key,
      });
      var bucketName = data.bucket_name;
      const params = {
        Bucket: this.asseceskey.bucket_name,
        Key: item.CurrentPath,
        Expires: 3600,
      };

      const presignedUrl = s3.getSignedUrl("getObject", params);
      const url = presignedUrl.toString();
      // this.dwonload_pdf(url);
      if (item.fileIcon == "mdi-file-document") {
        this.dwonload_pdf(url, "File.pdf");
      } else if (item.fileIcon == "mdi-video") {
        this.dwonload_pdf(url, "Video.mp3");
      } else if (item.fileIcon == "mdi-camera-image") {
        this.dwonload_pdf(url, "Image.jpeg");
      } else if (item.fileIcon === "mdi-folder-zip") {
        this.dwonload_pdf(url, "folder.zip");
      } else if (item.fileIcon === "mdi-square-rounded-badge") {
        this.dwonload_pdf(url, "Icon.icon");
      } else if (item.fileIcon === "mdi-file-chart") {
        this.dwonload_pdf(url, "file.xlsx");
      }

      // window.open(url, "_blank");
    },

    async dwonload_pdf(url, fileName) {
      axios({
        url: url,
        method: "GET",
        responseType: "blob", // Set the response type to blob
      })
        .then((response) => {
          // Create a blob from the response
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });

          // Use the FileSaver library to save the file
          FileSaver.saveAs(blob, fileName);
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        });
    },

    getFileIcon(key) {
      var ext = key.split(".").pop();

      var fileIcon = "mdi-folder";
      switch (ext) {
        case "png":
          fileIcon = "mdi-camera-image";
          break;
        case "jpg":
          fileIcon = "mdi-camera-image";
          break;
        case "jpeg":
          fileIcon = "mdi-camera-image";
          break;
        case "csv":
          fileIcon = "mdi-file-document";
          break;
        case "pdf":
          fileIcon = "mdi-file-document";
          break;
        case "docx":
          fileIcon = "mdi-file-document";
          break;
        case "xlsx":
          fileIcon = "mdi-file-chart";
          break;
        case "pptx":
          fileIcon = "mdi-file-document";
          break;
        case "mp3":
          fileIcon = "mdi-video";
          break;
        case "mp4":
          fileIcon = "mdi-video";
          break;
        case "xls":
          fileIcon = "mdi-file-document";
          break;
        case "bin":
          fileIcon = "mdi-file-document";
          break;
        case "zip":
          fileIcon = "mdi-folder-zip";
          break;
        case "ico":
          fileIcon = "mdi-square-rounded-badge";
          break;
      }

      return fileIcon;
    },

    new_folder_creation(values) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: values,
        timeout: 5000,
        Top: true,
      };
      this.createFolderDialog = false;
      this.uploadFileDialog = false;
      this.firstCheck = 1;
      this.fetch_folders(this.primaryKey);
    },

    fetch_value(val) {
      const d = new Date(parseInt(val) * 1000);
      var date = d.getDate().toLocaleString();
      var month = (d.getMonth() + 1).toLocaleString();
      var year = d.getFullYear();

      return (
        (+date <= 9 ? "0" + date : date) +
        "/" +
        (+month <= 9 ? "0" + month : month) +
        "/" +
        year
      );
    },
  },
};
</script>