var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('div',{staticClass:"mr-10"},[_c('h3',[_vm._v("Banner")])])]),_c('v-spacer'),(_vm.itemstablebanner.length < 5)?_c('v-btn',{staticClass:"gradient-bg white--text",on:{"click":function($event){return _vm.addBanner()}}},[_vm._v("Create")]):_vm._e()],1),_c('v-data-table',{staticClass:"overflow-y-auto overflow",attrs:{"headers":_vm.organisationData,"items":_vm.itemstablebanner,"dense":"","fixed-header":_vm.fixed,"height":_vm.height,"search":_vm.search,"items-per-page":100,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.banner_description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"caption"},on),[_vm._v(" "+_vm._s(item.banner_description.length >= 50 ? item.banner_description.substr(0, 50) + "..." : item.banner_description)+" ")])]}}],null,true)},[_c('div',{staticClass:"textWrap white--text",staticStyle:{"max-width":"250px"}},[_vm._v(" "+_vm._s(item.banner_description)+" ")])])]}},{key:"item.banner_routing_url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.banner_routing_url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.banner_routing_url)+" ")])]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.view_dailog(item)}}},on),[_vm._v(" mdi-information-variant-circle-outline ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("view")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.editdailog(item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("view")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.delete_dailog(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Delete")])])]}},{key:"item.banner_created_on",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.get_date(item.banner_created_on)))])]}}],null,true)})],1)],1),(_vm.componentCheck == 1)?_c('div',[_c('BannerView',{attrs:{"viewBanner":_vm.viewBanner,"banneritems":_vm.banneritems},on:{"clicked":function($event){_vm.viewBanner = false}}})],1):_vm._e(),(_vm.componentCheck == 2)?_c('div',[_c('CreateBanner',{attrs:{"bannerCreation":_vm.bannerCreation},on:{"clicked":function($event){_vm.bannerCreation = false},"errorMsg":_vm.error_info,"successMsg":_vm.success_info}})],1):_vm._e(),(_vm.componentCheck == 3)?_c('div',[_c('DeletBanner',{attrs:{"bannerDeletion":_vm.bannerDeletion,"banneritems":_vm.banneritems},on:{"clicked":function($event){_vm.bannerDeletion = false},"errorMsg":_vm.error_info,"successMsg":_vm.success_info}})],1):_vm._e(),(_vm.componentCheck == 4)?_c('div',[_c('EditBanner',{attrs:{"bannerEdition":_vm.bannerEdition,"banneritems":_vm.banneritems},on:{"clicked":function($event){_vm.bannerEdition = false},"errorMsg":_vm.error_info,"successMsg":_vm.success_info}})],1):_vm._e(),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('Snackbar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }