import { API, graphqlOperation } from "aws-amplify";
import { getAdminLatestVersion } from "@/graphql/queries.js";
export const GetAdminlatestVersion = {
  data() {
    return {
      adminVersionDetails: {}, 
    };
  },
  methods: {
    async GetPrezenceAdminVersionMethod() {
      // var self = this;
      let result = await API.graphql(
        graphqlOperation(getAdminLatestVersion, {
          input: {},
        })
      );
      this.adminVersionDetails = JSON.parse(
        result.data.getAdminLatestVersion
      ).PrezenceAdminappLatestVersion[0];
      // console.log("adminVersionDetails", this.adminVersionDetails);
      
    },
  },
};
