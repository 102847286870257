<template>
  <div>
    <v-dialog v-model="EditCreditDialog" persistent width="600px">
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title class="white--text">Edit Credits</v-toolbar-title>
        <v-spacer />

        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-card-text class="mt-4">
          <v-form ref="form">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="CountryName"
                  color="primary"
                  label="Country"
                  readonly
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="CreditsNo"
                  color="primary"
                  label="NO of Credits"
                  :items="itemCredits"
                  item-text="name"
                  item-value="value"
                  outlined
                  class=""
                  dense
                />
                <!-- <v-text-field
                  v-model="CreditsNo"
                  color="primary"
                  label="NO of Credits"
                  :rules="number_rules"
                  outlined
                  dense
                /> -->
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="Beacon"
                  color="primary"
                  label="Beacon"
                  :items="itemCredits"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="mt-n6"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="Camera"
                  color="primary"
                  label="Camera"
                  :items="itemCredits"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="mt-n6"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="datacorrection"
                  color="primary"
                  label="DataCorrection"
                  :items="itemCredits"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="mt-n6"
                  dense
                />
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="desktop"
                  color="primary"
                  label="Desktop"
                  :items="itemCredits"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="mt-n6"
                  dense
                />
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="facescan"
                  color="primary"
                  label="Face"
                  :items="itemCredits"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="mt-n6"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="proxy"
                  color="primary"
                  label="Proxy"
                  :items="itemCredits"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="mt-n6"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="rfid"
                  color="primary"
                  label="Rfid"
                  :items="itemCredits"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="mt-n6"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="formfill"
                  color="primary"
                  label="Form"
                  :items="itemCredits"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="mt-n6"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="simple"
                  color="primary"
                  label="Simple"
                  :items="itemCredits"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="mt-n6"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="live"
                  color="primary"
                  label="Live"
                  :items="itemCredits"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="mt-n6"
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            depressed
            :loading="loading"
            @click="save_mutation()"
            dark
            class="gradient-bg white--text text-capitalize mt-2"
          >
            Save
          </v-btn>
        </v-card-actions>

        <v-card-actions />
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { API, graphqlOperation } from "aws-amplify";
import { country_credits_list_edit } from "@/graphql/queries.js";
export default {
  props: {
    EditCreditDialog: Boolean,
    credititems: Object,
  },
  data: () => ({
    number_rules: [
      (v) => !!v || "required ",
      (value) => /^\d+$/.test(value) || "Only numbers are allowed",
    ],
    itemCredits: [
      { name: "1", value: 1 },
      { name: "2", value: 2 },
      { name: "3", value: 3 },
    ],
    CreditsNo: "",
    CountryName: "",
    Beacon: "",
    loading: false,
    Camera: "",
    datacorrection: "",
    desktop: "",
    facescan: "",
    proxy: "",
    rfid: "",
    formfill: "",
    simple: "",
    live: "",
  }),
  watch: {
    EditCreditDialog: {
      async handler() {
        this.CountryName = this.credititems.country_name;
        this.CreditsNo = this.credititems.credits;
        this.Beacon = this.credititems.BEACON;
        this.Camera = this.credititems.CAMERA;
        this.datacorrection = this.credititems.DATACORRECTION;
        this.desktop = this.credititems.DESKTOP;
        this.facescan = this.credititems.FACE;
        this.proxy = this.credititems.PROXY;
        this.rfid = this.credititems.RFID;
        this.formfill = this.credititems.FORM;
        this.simple = this.credititems.SIMPLE;
        this.live = this.credititems.LIVE;
        console.log(this.credititems);
      },
      immediate: true,
    },
  },
  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
    },
    async save_mutation() {
      this.loading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(country_credits_list_edit, {
            input: {
              action_type: "EDIT",
              country_id: this.credititems.country_id,
              credits: this.CreditsNo,
              BEACON: this.Beacon,
              CAMERA: this.Camera,
              DATACORRECTION: this.datacorrection,
              DESKTOP: this.desktop,
              FACE: this.facescan,
              PROXY: this.proxy,
              RESWIPE: this.credititems.RESWIPE,
              RFID: this.rfid,
              WHATSAPP: this.credititems.WHATSAPP,
              VISITORSCAN: this.credititems.VISITORSCAN,
              FORM: this.formfill,
              SIMPLE: this.simple,
              LIVE: this.live,
            },
          })
        );

        var response = JSON.parse(result.data.country_credits_list_edit);
        this.loading = false;
        if (response.Status == "SUCCESS") {
          this.loading = false;
          this.$emit("successMsg", response.Message);
        } else {
          this.loading = false;
          this.$emit("errorMsg", response.Message);
        }
      } catch (error) {
        this.loading = false;

        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.errors[0].message,
          timeout: 5000,
          Top: true,
        };
      }
    },
  },
};
</script>
  
  <style>
</style>