<template>
  <div>
    <v-card flat>
      <v-toolbar dense elevation="0" class="mt-5">
        <div class="mr-10"><h3>Country Credit</h3></div>
        <v-spacer />
        <v-select
          dense
          outlined
          label="Credits"
          v-model="countryCredits"
          style="max-width: 150px"
          :items="itemcredits"
          class="mr-4 mt-5"
          @change="get_country_list()"
        />
        <v-text-field
          v-model="search"
          label="search"
          class="mr-2 mt-5"
          style="max-width: 250px"
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="countryheaders"
          :items="countryitemss"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :height="height"
          :search="search"
          :loading="isLoading"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 30, 40, 50],
          }"
        >
          <template v-slot:[`item.country_name`]="{ item }">
            <v-avatar size="25" class="mt-1 mr-2 mb-1">
              <v-img :src="item.country_flag" />
            </v-avatar>
            <span>{{ item.country_name }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small color="primary" @click="edit_event(item)"
              >mdi-pencil</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div v-if="componentCheck == 1">
      <CreditsEdit
        :EditCreditDialog="EditCreditDialog"
        @clicked="EditCreditDialog = false"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
        :credititems="credititems"
      />
    </div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import CreditsEdit from "../Dialogs/CreditsEdit.vue";
import Overlay from "@/components/Overlay.vue";
import { API, graphqlOperation } from "aws-amplify";
import { country_credits_list_edit } from "@/graphql/queries.js";
import Snackbar from "@/components/SnackBar.vue";
export default {
  components: {
    Snackbar,
    Overlay,
    CreditsEdit,
  },
  data() {
    return {
      countryheaders: [
        { text: "Country ", value: "country_name" },
        { text: "Country  Code", value: "dail_code" },

        { text: "Credits", value: "credits" },
        { text: "Action", value: "actions", sortable: false },
      ],
      countryCredits: "ALL",
      countryitemss: [],
      itemcredits: [
        { text: "All", value: "ALL" },
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
      ],
      componentCheck: 0,
      fixed: true,
      overlay: false,
      search: "",
      SnackBarComponent: {},
      credititems: {},
      isLoading: false,
      EditCreditDialog: false,
    };
  },
  created() {
    this.height = window.innerHeight - 210;
    this.get_country_list();
  },
  methods: {
    edit_event(item) {
      this.credititems = item;
      this.componentCheck = 1;
      this.EditCreditDialog = true;
    },
    async get_country_list() {
      this.overlay = true;
      this.countryitemss = [];
      try {
        let result = await API.graphql(
          graphqlOperation(country_credits_list_edit, {
            input: {
              action_type: "GET",
            },
          })
        );
        this.overlay = false;
        var response = JSON.parse(result.data.country_credits_list_edit);
        if (response.Status == "SUCCESS") {
          this.countryitemss = response.data;
          // console.log(response.data);
          if (this.countryCredits === "ALL") {
            this.countryitemss;
          } else {
            this.countryitemss = this.countryitemss.filter(
              (item) => item.credits === this.countryCredits
            );
          }
        }
      } catch (error) {
        this.overlay = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.errors[0].message,
          timeout: 5000,
          Top: true,
        };
      }
    },

    success_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
      this.EditCreditDialog = false;
      this.get_country_list();
    },
    error_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },
  },
};
</script>

<style>
</style>