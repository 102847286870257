import { API, graphqlOperation } from "aws-amplify";
import { getMyPrezenceLatestVersion } from "@/graphql/queries.js";


export const GetPrezenceVersion = {
  data() {
    return {
      versionDetails: {},
    

    };
  },
  methods: {
    async GetPrezenceVersionMethod() {
      // var self = this;
      //let result is our wish.we can give any name
      let result = await API.graphql(
        graphqlOperation(getMyPrezenceLatestVersion, {
          input: {},
        })

      );
      //we use that result in result.data. 
      //we use Json parse to get the details according to result
      //do console.log
      // console.log("resulttt",result)
      this.versionDetails = JSON.parse(
        result.data.getMyPrezenceLatestVersion
      ).MyPrezenceLatestVersion[0];
      
      // console.log("versionDetails", this.versionDetails);
    },
  },
 
}







